// Core modules
import {Injectable} from '@angular/core';

// Third-party modules
import {Subject} from 'rxjs';

@Injectable()
export class RelatedService {

    /**
     * Data members
     */
    public sharedRelatedOpeningSubject: Subject<string> = new Subject();

    /**
     * @function constructor
     */
    constructor() {}

    /**
     * @function openInNewTab
     * @description
     * @public
     * @param {sting} url
     * @returns {void}
     */
    public openInNewTab(url: string): void {
        const win = window.open(url, '_blank');
        if (win) {
            win.focus();
        }
    }

}
