// Core modules
import {Injectable} from '@angular/core';

// Internal interfaces
import {Attendee, SessionStatus} from '@app/core/messaging/session-state';

// Internal models
import {DynamicContentOCE} from '@app/shared/models/dynamic_content/dynamic-content-oce';
import {DynamicContentPresentation} from '@app/shared/models/dynamic_content/presentation';
import {DynamicContentSequence} from '@app/shared/models/dynamic_content/sequence';
import {DynamicContentSlide} from '@app/shared/models/dynamic_content/slide';
import {DynamicContentCustomer} from '@app/shared/models/dynamic_content/customer';

// Internal services
import {AuthenticationService} from '@app/core/authentication/authentication.service';

@Injectable()
export class SessionStatusService {

    /**
     * Data members
     */
    private _sessionStatus: SessionStatus;
    private _dynamicContent: string;

    /**
     * @function constructor
     * @param {AuthenticationService} _authService
     */
    constructor(
        private _authService: AuthenticationService
    ) {}

    /**
     * @function sessionStatus
     * @description Getter for sessionStatus
     * @public
     * @returns {any}
     */
    get sessionStatus() {
        return this._sessionStatus;
    }

    /**
     * @function sessionStatus
     * @description Setter for sessionStatus
     * @public
     * @param sessionStatus
     * @returns {void}
     */
    set sessionStatus(sessionStatus: SessionStatus) {
        this._sessionStatus = sessionStatus;
    }

    /**
     * @function dynamicContent
     * @description Getter for dynamicContent
     * @public
     * @returns {any}
     */
    get dynamicContent() {
        return this._dynamicContent;
    }

    /**
     * @function dynamicContent
     * @description Setter for dynamicContent
     * @public
     * @param dynamicContent
     * @returns {void}
     */
    set dynamicContent(dynamicContent: any) {
        this._dynamicContent = dynamicContent;
    }

    /**
     * @function buildOCEDynamicContent
     * @description
     * @public
     * @param {SessionStatus} sessionStatus
     * @returns {void}
     */
    public buildOCEDynamicContent(): void {
        const dynamicContent = new DynamicContentOCE();
        const indexes: any = this._getPresentationIndex(this.sessionStatus.presentations, this.sessionStatus.currentSlide.presentationIdentifier, this.sessionStatus.currentSlide.sequenceIdentifier, this.sessionStatus.currentSlide.slideName);
        dynamicContent.setCustomers = this._formatCustomers(this.sessionStatus.customers);
        dynamicContent.setPresentations = this._formatPresentations(this.sessionStatus.presentations);
        dynamicContent.setPresentationIndex = indexes.presentationIndex;
        dynamicContent.setSequenceIndex = indexes.sequenceIndex;
        dynamicContent.setSlideIndex = indexes.slideIndex;
        this.dynamicContent = dynamicContent;
    }

    /**
     * @function _getPresentationIndex
     * @description
     * @private
     * @param {any[]} presentations
     * @param {string} presentationIdentifier
     * @returns {number}
     */
    private _getPresentationIndex(presentations: any[], presentationIdentifier: string, sequenceIdentifier: string, slideName: string): number {
        const indexes: any = {};
        for (const [i, presentation] of presentations.entries()) {
            if (presentation.identifier === presentationIdentifier) {
                indexes['presentationIndex'] = i;
            }
            for (const [j, sequence] of presentation.sequences.entries()) {
                if (sequence.identifier === sequenceIdentifier) {
                    indexes['sequenceIndex'] = j;
                }
                for (const [k, slide] of sequence.slides.entries()) {
                    if (slide.name === slideName) {
                        indexes['slideIndex'] = k;
                    }
                }
            }
        }
        return indexes;
    }

    /**
     * @function _formatPresentations
     * @description
     * @private
     * @param {any[]} presentations
     * @returns {DynamicContentPresentation[]}
     */
    private _formatPresentations(presentations: any[]): DynamicContentPresentation[] {
        const newFormatObj: DynamicContentPresentation[] = [];

        // Looping on presentations
        for (const presentation of presentations) {
            const newPresentation: DynamicContentPresentation = {
                id: presentation.identifier,
                externalid: '',
                name: presentation.name,
                sequences: []
            };

            // Looping on sequences
            for (const sequence of presentation.sequences) {
                const newSequence: DynamicContentSequence = {
                    id: sequence.identifier,
                    slides: []
                };

                // Looping on slides
                for (const slide of sequence.slides) {
                    const newSlide: DynamicContentSlide = {
                        name: slide.name
                    };
                    newSequence.slides.push(newSlide);
                }
                newPresentation.sequences.push(newSequence);
            }
            newFormatObj.push(newPresentation);
        }

        return newFormatObj;
    }

    /**
     * @function _formatCustomers
     * @description
     * @private
     * @param {any[]} customers
     * @returns {DynamicContentCustomer[]}
     */
    private _formatCustomers(customers: any[]): DynamicContentCustomer[] {
        const newFormatObj: DynamicContentCustomer[] = [];

        for (const customer of customers) {
            const newCustomer: DynamicContentCustomer = {
                id: '',
                suffix_name: '',
                nationality: '',
                account_type: '',
                lastname: customer.lastName,
                middle_name: '',
                firstname: customer.firstName,
                specialty: '',
                onekeyid: '',
                salutation: '',
                email: '',
                isPrimary: false,
                customertype: ''
            };
            newFormatObj.push(newCustomer);
        }

        return newFormatObj;
    }

    /**
     * @function getAttendeeSurvey
     * @description
     * @public
     * @param {string} username
     * @returns {string}
     */
    public getAttendeeSurvey(username: string): string {
        const attendees: Attendee[] = this.sessionStatus && this.sessionStatus.customers;
        // Cleaning user's username
        let myUid: string = username && username.split('@')[0];
        if (myUid && myUid.indexOf('token_') !== -1) {
            myUid = myUid.substring(6);
        }
        const myAttendeeData: Attendee[] = attendees && attendees.filter((attendee: Attendee) => {
            return attendee.identifier.toLowerCase() === myUid.toLowerCase();
        });
        const satisfactionSurveyURL = myAttendeeData && myAttendeeData.length && myAttendeeData[0].satisfactionSurveyURL;
        if (satisfactionSurveyURL) {
            return satisfactionSurveyURL;
        } else {
            console.warn('No satisfactionSurveyURL provided.');
            return null;
        }
    }

}
