// Core modules
import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// Third-party modules
import {TranslateService} from '@ngx-translate/core';
import {finalize} from 'rxjs/operators';
// Internal modules
import {environment} from '@env/environment';
// Internal components
import {GdprComponent} from './gdpr/gdpr.component';
// Internal services
import {I18nService} from '@app/core/i18n.service';
import {Logger} from '@app/core/logger.service';
import {CustoService} from '@app/shared/service/custo.service';
import {AuthenticationService, ErrorMessage} from '@app/core/authentication/authentication.service';
import {FlashService} from '@app/shared/flash/flash.service';
import {VersionService} from '@app/shared/service/version.service';
import {BrowserService} from '@app/shared/service/browser.service';
import {Subscription} from 'rxjs';

// Global variables declaration
const log = new Logger('Login');

export enum TokenAction {
    JOIN = 'join'
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy, OnInit, AfterViewInit {

    /**
     * Data members
     */
    public paramsLoaded: boolean;
    public custoLoaded: boolean;
    public version: string;
    public loginForm: FormGroup;
    public isLoading = false;
    public modalType: string;
    public modalUrlToLoad: string;
    public modalTransToLoad: string;
    public modalComponentToLoad: any;
    public isModalDisplayed: boolean;
    public isLegalDisplayed: boolean;
    public isDisclaimerDisplayed: boolean;
    public isGdprDisplayed: boolean;
    public isExternalAccess: boolean;
    public isDesktop: boolean;
    public showLanguagesDropdown: boolean;
    public PARAMS: any = {};
    public CUSTO: any = {};
    public availableLocales: string[];
    private _translations: string[] = [];
    private _token: string;
    private _action: string;
    private _uid: string;
    private _forceDisclaimers: string;
    private _subscriptions: Subscription[] = [];

    /**
     * @function constructor
     * @param {CustoService} _custoService
     * @param {Router} _router
     * @param {FormBuilder} _formBuilder
     * @param {I18nService} _i18nService
     * @param {AuthenticationService} _authenticationService
     * @param {TranslateService} _translateService
     * @param {FlashService} _flashService
     * @param {VersionService} _versionService
     * @param {ActivatedRoute} _route
     * @param {BrowserService} _browserService
     */
    constructor(
        private _custoService: CustoService,
        private _router: Router,
        private _formBuilder: FormBuilder,
        private _i18nService: I18nService,
        private _authenticationService: AuthenticationService,
        private _translateService: TranslateService,
        private _flashService: FlashService,
        private _versionService: VersionService,
        private _route: ActivatedRoute,
        private _browserService: BrowserService
    ) {
        this._detectIE11();
        this._initTranslations();
        this._createForm();
        this._displayVersion();
        this._detectRtlLanguages();
    }

    /**
     * @function currentLanguage
     * @description
     * @public
     * @returns {string}
     */
    public get currentLanguage(): string {
        return this._i18nService.language;
    }

    /**
     * @function browsers
     * @description
     * @public
     * @returns {string[]}
     */
    get browsers(): any[] {
        let browserNames: any[] = [];
        const chrome: any = {
            name: this._translations['chrome'],
            fullName: this._translations['google_chrome'],
            downloadLink: 'https://www.google.com/chrome/'
        };
        const firefox: any = {
            name: this._translations['firefox'],
            fullName: this._translations['mozilla_firefox'],
            downloadLink: 'https://www.mozilla.org/firefox/'
        };
        const safari: any = {
            name: this._translations['safari'],
            fullName: this._translations['apple_safari'],
            downloadLink: 'https://www.apple.com/safari/'
        };

        if (this._browserService.isAndroid()) {
            browserNames = [chrome];
        } else if (this._browserService.isIos()) {
            browserNames = [safari];
        } else if (this._browserService.isDesktop() && this._browserService.isMacOS()) {
            browserNames = [chrome, firefox, safari];
        } else if (this._browserService.isDesktop() && !this._browserService.isMacOS()) {
            browserNames = [chrome, firefox];
        }
        return browserNames;
    }

    /**
     * @function browsersNames
     * @description
     * @public
     * @returns {string}
     */
    get browsersNames() {
        return this.browsers.map(browser => browser.fullName).join(', ');
    }

    /**
     * @function onResize
     * @description
     * @public
     * @returns {void}
     */
    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this._initView();
    }

    /**
     * @function onClick
     * @description
     * @public
     * @param {any} $event
     * @returns {void}
     */
    @HostListener('window:click', ['$event'])
    onClick($event: any): void {
        const target = $event.target;
        const parentTarget = target.parentElement;
        if (target && target.id !== 'language-dropdown' && !target.classList.contains('dropdown-menu') &&
            parentTarget && parentTarget.id !== 'dropdown-menu' && !parentTarget.classList.contains('dropdown-menu')) {
            this.showLanguagesDropdown = false;
        }
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        // Listener for error messages sent by other components, especially when logging out
        this._listenToErrorMessages();

        this._route.queryParamMap.subscribe((params) => {
            this._token = params.get('token');
            this._action = params.get('action');
            this._uid = params.get('uid');
            this._forceDisclaimers = params.get('force');
        });

        /**
         * Dealing with external access such as join link from invitation email
         * For appropriate browsers, if token parameter exists and action parameter equals "join"
         * Then we automatically log the user into the app (V2/V3 tokens)
         */
        if (!this.isWrongBrowser() && this._token && this._action === TokenAction.JOIN && (!this._uid || (this._uid && this._uid !== ''))) {
            this.isExternalAccess = true;
        }

        // Initializing the different possible views
        this._initParams();
    }

    /**
     * @function ngOnDestroy
     */
    ngOnDestroy() {
        this._subscriptions.forEach((subscription: Subscription) => {
           subscription.unsubscribe();
        });
    }

    /**
     * @function _shouldAutoValidateForm
     * @description
     * @private
     * @returns {void}
     */
    public _shouldAutoValidateForm(): void {
        if (this.isExternalAccess &&
            // If disclaimers by-passing is required
            (this._forceDisclaimers === 'true' ||
                (
                    // If none of the disclaimer and GRPD has a custom value
                    (!this.CUSTO['loginPageGdprText'] && !this.CUSTO['loginPageDisclaimerText']) ||
                    // Or if there is no disclaimer custom value and if the GRPD has already been accepted
                    (!this.CUSTO['loginPageDisclaimerText'] && this.CUSTO['loginPageGdprText'] && this._isGdprAlreadyAccepted())
                )
            )
        ) {
            this.autoValidateForm();
        }
    }

    /**
     * @function autoValidateForm
     * @description
     * @public
     * @returns {void}
     */
    public autoValidateForm(): void {
        const token = this._token;
        const uid = this._uid;
        const anonymousUsername = 'anonymous' + Math.floor(Math.random() * 100000) + 1;
        let username;
        if (token) {
            if (token.length === 6) {
                if (!uid) {
                    username = anonymousUsername;
                } else {
                    username = uid;
                }
            } else if (token.length === 8) {
                username = null;
            }
        }
        this._authenticationService.login({
            username: username,
            password: token,
            remember: false
        })
            .pipe(finalize(() => {
                this.isLoading = false;
            }))
            .subscribe(() => {
                this._router.navigate(['/'], {replaceUrl: true});
            }, error => {
                let message;
                message = this._translations['session_key_invalid'];
                this._flashService.error(message, 15000);
            });
    }

    /**
     * @function ngAfterViewInit
     */
    ngAfterViewInit() {
        // prevent double scroll. scroll property on the IFrame GTU is necessary only in iphone|IPad
        const iframeWrapper = document.getElementById('iframeWrapper');
        if (iframeWrapper && (this._browserService.isIphone() || this._browserService.isIpad())) {
            iframeWrapper.style.overflowY = 'scroll';
        }
    }

    /**
     * @function getGlobalClassnames
     * @description
     * @public
     * @returns {string}
     */
    public getGlobalClassnames(): string {
        let classNames: string = '';
        if (this.isExternalAccess) {
            classNames += 'externalAccessStyle';
        }
        if (this.CUSTO.loginPageTextColorTheme) {
            classNames += ' ' + this.CUSTO.loginPageTextColorTheme;
        }
        return classNames;
    }

    /**
     * @function getMainClassnames
     * @description
     * @public
     * @returns {string}
     */
    public getMainClassnames(): string {
        let classNames: string = '';
        const hasAddress: boolean = this.CUSTO['loginPageAddressText'];
        const hasJoinBtn: boolean = this.isExternalAccess && this.CUSTO['loginPageDisclaimerText'] && (!this.CUSTO['loginPageGdprText'] || (this.CUSTO['loginPageGdprText'] && this._isGdprAlreadyAccepted()));
        if (hasAddress) {
            classNames = 'has-address';
        }
        if (hasJoinBtn) {
            classNames = 'has-join-btn';
        }
        if (hasAddress && hasJoinBtn) {
            classNames = 'has-address-and-join-btn';
        }
        if (this.PARAMS['oceBasicStyle']) {
            classNames += ' oce-basic-style';
        }
        return classNames;
    }

    /**
     * @function getBackgroundStyle
     * @description
     * @public
     * @returns {any}
     */
    public getBackgroundStyle(): any {
        const style: any = {};
        // Custo for mobile
        if (this._isMobileView()) {
            if (this.CUSTO['loginPageBackgroundColorMobile']) {
                style.backgroundColor = this.CUSTO['loginPageBackgroundColorMobile'];
            }
            // Fallback with desktop data if no mobile background-color available
            else if (this.CUSTO['loginPageBackgroundColorDesktop']) {
                style.backgroundColor = this.CUSTO['loginPageBackgroundColorDesktop'];
            }

            if (this.CUSTO['loginPageBackgroundImageMobile']) {
                style.backgroundImage = 'url(' + this.CUSTO['loginPageBackgroundImageMobile'] + ')';
                style.backgroundRepeat = 'no-repeat';
            }
            // Fallback with desktop data if no mobile background-image available
            else if (this.CUSTO['loginPageBackgroundImageDesktop']) {
                style.backgroundImage = 'url(' + this.CUSTO['loginPageBackgroundImageDesktop'] + ')';
                style.backgroundRepeat = 'no-repeat';
            }

            if (this.CUSTO['loginPageBackgroundPositionMobile']) {
                style.backgroundPosition = this.CUSTO['loginPageBackgroundPositionMobile'];
            }
            // Fallback with desktop data if no mobile background-position available
            else if (this.CUSTO['loginPageBackgroundPositionDesktop']) {
                style.backgroundPosition = this.CUSTO['loginPageBackgroundPositionDesktop'];
            }

            if (this.CUSTO['loginPageBackgroundSizeMobile']) {
                style.backgroundSize = this.CUSTO['loginPageBackgroundSizeMobile'];
            }
            // Fallback with desktop data if no mobile background-size available
            else if (this.CUSTO['loginPageBackgroundSizeDesktop']) {
                style.backgroundSize = this.CUSTO['loginPageBackgroundSizeDesktop'];
            }
        }
        // Custo for desktop
        else {
            if (this.CUSTO['loginPageBackgroundColorDesktop']) {
                style.backgroundColor = this.CUSTO['loginPageBackgroundColorDesktop'];
            }
            if (this.CUSTO['loginPageBackgroundImageDesktop']) {
                style.backgroundImage = 'url(' + this.CUSTO['loginPageBackgroundImageDesktop'] + ')';
                style.backgroundRepeat = 'no-repeat';
            }
            if (this.CUSTO['loginPageBackgroundPositionDesktop']) {
                style.backgroundPosition = this.CUSTO['loginPageBackgroundPositionDesktop'];
            }
            if (this.CUSTO['loginPageBackgroundSizeDesktop']) {
                style.backgroundSize = this.CUSTO['loginPageBackgroundSizeDesktop'];
            }
        }
        return style;
    }

    /**
     * @function getButtonStyle
     * @description
     * @public
     * @returns {any}
     */
    public getButtonStyle(): any {
        const style: any = {};
        style.borderColor = this.CUSTO['loginPageButtonColor'];
        style.backgroundColor = this.CUSTO['loginPageButtonColor'];
        return style;
    }

    /**
     * @function openInNewTab
     * @description
     * @public
     * @param {string} url
     * @return {void}
     */
    public openInNewTab(url: string = null): void {
        if (!url) {
            return;
        }
        window.open(url, '_blank');
    }

    /**
     * @function login
     * @description
     * @public
     * @returns {void}
     */
    public login(): void {
        this.isLoading = true;
        this._authenticationService
            .login(this.loginForm.value)
            .pipe(finalize(() => {
                this.loginForm.markAsPristine();
                this.isLoading = false;
            }))
            .subscribe(() => {
                log.debug(`Successfully authenticated`);
                this._goToMainPage();
            }, error => {
                log.debug(`Login error`, error);
                let message = '';
                if (error.error.error === 'USER_AND_PASSWORD_NOT_VALID') {
                    message = this._translations['session_key_invalid'];
                }
                this._flashService.error(message, 15000);
            });
    }

    /**
     * @function setLanguage
     * @description
     * @public
     * @param {any} $event
     * @param {string} language
     * @returns {void}
     */
    public setLanguage($event: any, language: string): void {
        $event.stopImmediatePropagation();
        this._i18nService.language = language;
        // Reinitializing previously saved translations
        this._initTranslations();
        // Updating custo
        this._parseCusto(language);
        // Should we auto-validate the form with those new custom values?
        this._shouldAutoValidateForm();
        this.showLanguagesDropdown = false;
    }

    /**
     * @function getCountryCode
     * @description
     * @public
     * @param {string} LanguageCountryCode
     * @returns {string}
     */
    public getCountryCode(LanguageCountryCode: string): string {
        let countryCode = LanguageCountryCode && LanguageCountryCode.split('-').pop();
        if (LanguageCountryCode === 'zh-Hans') {
            countryCode = 'CN';
        }
        return countryCode;
    }

    /**
     * @function loadModalText
     * @description
     * @public
     * @returns {void}
     */
    public loadModalText(): void {
        if (this.modalTransToLoad) {
            return this._translateService.instant(this.modalTransToLoad);
        }
    }

    /**
     * @function displayDisclaimer
     * @description
     * @public
     * @returns {void}
     */
    public displayDisclaimer(): void {
        if (this.CUSTO['loginPageDisclaimerText']) {
            this.isLegalDisplayed = true;
            this.isDisclaimerDisplayed = true;
        } else {
            this.isDisclaimerDisplayed = false;
            // Hiding legal container if GRPD has not to be displayed
            if (!this.CUSTO['loginPageGdprText']) {
                this.isLegalDisplayed = false;
            }
        }
    }

    /**
     * @function _displayGdprIfNeeded
     * @description
     * @private
     * @returns {void}
     */
    public _displayGdprIfNeeded(): void {
        if (this.CUSTO['loginPageGdprText'] && !this._isGdprAlreadyAccepted()) {
            this.isLegalDisplayed = true;
            this.isGdprDisplayed = true;
        } else {
            this.isGdprDisplayed = false;
            // Hiding legal container if disclaimer has not to be displayed
            if (!this.CUSTO['loginPageDisclaimerText']) {
                this.isLegalDisplayed = false;
            }
        }
    }

    /**
     * @function displayGdprComponent
     * @description
     * @public
     * @returns {void}
     */
    public displayGdprComponent(): void {
        this.isModalDisplayed = true;
        this.modalComponentToLoad = GdprComponent;
    }

    /**
     * @function closeModal
     * @description
     * @public
     * @returns {void}
     */
    public closeModal(): void {
        this.isModalDisplayed = false;
        if (this.modalUrlToLoad) {
            this.modalUrlToLoad = null;
        }
        if (this.modalTransToLoad) {
            this.modalTransToLoad = null;
        }
        if (this.modalComponentToLoad) {
            this.modalComponentToLoad = null;
        }
    }

    /**
     * @function setFullScreen
     * @description
     * @public
     * @returns {void}
     */
    public setFullScreen(): void {
        if (environment.env === 'local') {
            return;
        }
        const elem: any = document.documentElement;
        if (typeof elem.webkitRequestFullscreen !== 'undefined') {
            elem.webkitRequestFullscreen();
        } else if (typeof elem.mozRequestFullScreen !== 'undefined') {
            elem.mozRequestFullScreen();
        } else if (typeof elem.msRequestFullscreen !== 'undefined') {
            elem.msRequestFullscreen();
        } else if (typeof elem.requestFullscreen !== 'undefined') {
            elem.requestFullscreen();
        }
    }

    /**
     * @function isWrongBrowser
     * @description
     * @public
     * @returns {boolean}
     */
    public isWrongBrowser(): boolean {
        // if (environment.env === 'local') { return; }
        return (
            (this._browserService.isIos() && !this._browserService.isSafari()) // If not Safari on iOS devices
            || (this._browserService.isAndroid() && !this._browserService.isChrome()) // If not Chrome on Android devices
            || (!this._browserService.isDesktop() && this._browserService.isFirefox()) // If Firefox mobile
            || this._browserService.isOpera() // If Opera
        );
    }

    /**
     * @function showFooter
     * @description
     * @public
     * @returns {void}
     */
    public showFooter(): void {
        document.getElementsByTagName('footer')[0].classList.remove('hide-footer');
    }

    /**
     * @function hideFooter
     * @description
     * @public
     * @returns {void}
     */
    public hideFooter(): void {
        document.getElementsByTagName('footer')[0].classList.add('hide-footer');
    }

    /**
     * @function acceptGdpr
     * @description
     * @public
     * @returns {void}
     */
    public acceptGdpr(): void {
        localStorage.setItem('gdpr', 'true');
        this.isGdprDisplayed = false;
        // For mobile devices and Desktop devices with a small width resolution, we hide the legal modal
        if ((this._browserService.isMobile() || (!this._browserService.isMobile() && document.body.clientWidth <= 823)) && !this.isExternalAccess) { // Pixel 2 XL's width resolution maximum
            this.isLegalDisplayed = false;
        }
        // Validating the form to log the user in automatically
        if (this.isExternalAccess) {
            this.autoValidateForm();
            this.setFullScreen();
        }
    }

    /**
     * @function displayJoinBtn
     * @description
     * @public
     * @returns {boolean}
     */
    public displayJoinBtn(): boolean {
        // Displaying Join button only when disclaimer is displayed (a custom GRPD text does not exist OR a custom GRPD text exists but already accepted)
        return this.isExternalAccess && this.CUSTO['loginPageDisclaimerText'] && (!this.CUSTO['loginPageGdprText'] || (this.CUSTO['loginPageGdprText'] && this._isGdprAlreadyAccepted()));
    }

    /**
     * @function copyUrlToClipboard
     * @description
     * @public
     * @returns {void}
     */
    public copyUrlToClipboard(): void {
        const url = window.location.href;
        const nav: any = navigator;
        if (nav && nav.clipboard) {
            nav.clipboard.writeText(url).then(
                () => {
                    console.log(url);
                },
                (err: any) => {
                }
            );
        }
    }

    /**
     * @function _detectRtlLanguages
     * @description
     * @private
     * @returns {void}
     */
    private _detectRtlLanguages(): void {
        this._translateService.onLangChange.subscribe((event: any) => {
            const rtlLangs: string[] = ['he-IL'];
            const html: any = document.getElementsByTagName('html')[0];
            if (rtlLangs.indexOf(event.lang) !== -1) {
                html.setAttribute('dir', 'rtl');
                html.classList.add('rtl');
            } else {
                html.removeAttribute('dir');
                html.classList.remove('rtl');
            }
        });
    }

    /**
     * @function _detectIE11
     * @description
     * @private
     * @returns {void}
     */
    private _detectIE11(): void {
        if (navigator && navigator.userAgent.indexOf('Trident/') > 0) {
            document.body.classList.add('ie11');
        }
    }

    /**
     * @function _initParams
     * @description
     * @private
     * @returns {void}
     */
    private _initParams(): void {
        this._custoService.paramsLoadedSubject.subscribe((loaded: boolean) => {
            this.paramsLoaded = loaded;
            this.PARAMS['availableLocales'] = this._custoService.getParam('availableLocales');
            this.PARAMS['defaultLocale'] = this._custoService.getParam('defaultLocale');
            this.PARAMS['oceBasicStyle'] = this._custoService.getParam('oceBasicStyle');
            this._i18nService.setAvailableLocales = this._custoService.getParam('availableLocales');
            this._i18nService.setDefaultLocale = this._custoService.getParam('defaultLocale');
            this._i18nService.language = null;
            this._initCusto(this.currentLanguage);
        });
    }

    /**
     * @function _initCusto
     * @description
     * @private
     * @param {string} locale
     * @returns {void}
     */
    private _initCusto(locale: string): void {
        this._custoService.custoLoadedSubject.subscribe((loaded: boolean) => {
            this.custoLoaded = loaded;
            this._parseCusto(locale);

            // Auto-submit the form to log the user in automatically
            this._shouldAutoValidateForm();
        });
    }

    /**
     * @function _parseCusto
     * @description
     * @private
     * @param {string} locale
     * @returns {void}
     */
    private _parseCusto(locale: string): void {
        this.CUSTO['loginPageLogoUrl'] = this._custoService.getProp('loginPageLogoUrl', locale);
        this.CUSTO['loginPageLogoBackUrl'] = this._custoService.getProp('loginPageLogoBackUrl', locale);
        this.CUSTO['loginPageBackgroundColorMobile'] = this._custoService.getProp('loginPageBackgroundColorMobile', locale);
        this.CUSTO['loginPageBackgroundImageMobile'] = this._custoService.getProp('loginPageBackgroundImageMobile', locale);
        this.CUSTO['loginPageBackgroundPositionMobile'] = this._custoService.getProp('loginPageBackgroundPositionMobile', locale);
        this.CUSTO['loginPageBackgroundSizeMobile'] = this._custoService.getProp('loginPageBackgroundSizeMobile', locale);
        this.CUSTO['loginPageBackgroundColorDesktop'] = this._custoService.getProp('loginPageBackgroundColorDesktop', locale);
        this.CUSTO['loginPageBackgroundImageDesktop'] = this._custoService.getProp('loginPageBackgroundImageDesktop', locale);
        this.CUSTO['loginPageBackgroundPositionDesktop'] = this._custoService.getProp('loginPageBackgroundPositionDesktop', locale);
        this.CUSTO['loginPageBackgroundSizeDesktop'] = this._custoService.getProp('loginPageBackgroundSizeDesktop', locale);
        this.CUSTO['loginPageAddressText'] = this._custoService.getProp('loginPageAddressText', locale);
        this.CUSTO['loginPageDisclaimerText'] = this._custoService.getProp('loginPageDisclaimerText', locale);
        this.CUSTO['loginPageLegalBackgroundColorTheme'] = this._custoService.getProp('loginPageLegalBackgroundColorTheme', locale);
        this.CUSTO['loginPageButtonColor'] = this._custoService.getProp('loginPageButtonColor', locale);
        this.CUSTO['loginPageTextColorTheme'] = this._custoService.getProp('loginPageTextColorTheme', locale);
        this.CUSTO['loginPageGdprTabName'] = this._custoService.getProp('loginPageGrpdTabName', locale);
        this.CUSTO['loginPageGdprText'] = this._custoService.getProp('loginPageGrpdText', locale);

        // Initializing view when custo is loaded (even if there is no custo)
        this._initView();
    }

    /**
     * @function _displayVersion
     * @description
     * @private
     * @returns {void}
     */
    private _listenToErrorMessages(): void {
        this._subscriptions.push(
            this._authenticationService.errorSubject.subscribe((data: string) => {
                if (data === ErrorMessage.DUAL_LOGIN) {
                    setTimeout(() => {
                        this._flashService.error(this._translations['already_connected'], 15000);
                    }, 500);
                }
            })
        );
    }

    /**
     * @function _displayVersion
     * @description
     * @private
     * @returns {void}
     */
    private _displayVersion() {
        this._versionService.getVersion()
            .subscribe((version: string) => {
                this.version = version;
                this._versionService.setVersion(version);
            });
    }

    /**
     * @function _initTranslations
     * @description
     * @private
     * @returns {void}
     */
    private _initTranslations(): void {
        this._translateService.get('Session key invalid')
            .subscribe((trans: string) => this._translations['session_key_invalid'] = trans);
        this._translateService.get('Chrome')
            .subscribe((trans: string) => this._translations['chrome'] = trans);
        this._translateService.get('Google Chrome')
            .subscribe((trans: string) => this._translations['google_chrome'] = trans);
        this._translateService.get('Safari')
            .subscribe((trans: string) => this._translations['safari'] = trans);
        this._translateService.get('Apple Safari')
            .subscribe((trans: string) => this._translations['apple_safari'] = trans);
        this._translateService.get('Firefox')
            .subscribe((trans: string) => this._translations['firefox'] = trans);
        this._translateService.get('Mozilla Firefox')
            .subscribe((trans: string) => this._translations['mozilla_firefox'] = trans);
        this._translateService.get('Already connected')
            .subscribe((trans: string) => this._translations['already_connected'] = trans);
    }

    /**
     * @function _initView
     * @description
     * @private
     * @returns {void}
     */
    private _initView() {
        // Dealing with the displaying of the disclaimer
        // It should be displayed directly in desktop view but accessible by clicking the disclaimer button at the bottom in mobile view
        // Updating those different boolean values when the view is initializing or when it is resizing by the user. It has to adapt automatically

        // For mobile devices
        if (this._isMobileView()) {
            this.isDesktop = false;
            // Basic access
            if (!this.isExternalAccess) {
                // Hiding disclaimer modal
                this.isDisclaimerDisplayed = false;
                // Then hiding global legal container if GRPD has not to be displayed
                if (!this.CUSTO['loginPageGdprText'] || (this.CUSTO['loginPageGdprText'] && this._isGdprAlreadyAccepted())) {
                    this.isLegalDisplayed = false;
                }
            }
            // External access
            else {
                // Showing disclaimer if custom loginPageDisclaimerText property is set
                this.displayDisclaimer();
            }
        }
        // For tablet/desktop devices
        else if (this._isDesktopView()) {
            this.isDesktop = true;
            // Showing disclaimer if custom loginPageDisclaimerText property is set
            this.displayDisclaimer();
        }

        this._displayGdprIfNeeded();
    }

    /**
     * @function _isMobileView
     * @description
     * @private
     * @param {number} windowWidth
     * @returns {boolean}
     */
    private _isMobileView(windowWidth: number = null) {
        if (!windowWidth) {
            windowWidth = document.body.clientWidth;
        }
        return this._browserService.isMobile() || (!this._browserService.isMobile() && windowWidth <= 823);
    }

    /**
     * @function _isDesktopView
     * @description
     * @private
     * @param {number} windowWidth
     * @returns {boolean}
     */
    private _isDesktopView(windowWidth: number = null) {
        if (!windowWidth) {
            windowWidth = document.body.clientWidth;
        }
        return !this._browserService.isMobile() && windowWidth > 823;
    }

    /**
     * @function _createForm
     * @description
     * @private
     * @returns {void}
     */
    private _createForm(): void {
        this.loginForm = this._formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            remember: true
        });
    }

    /**
     * @function _goToMainPage
     * @private
     * @description
     * @returns {void}
     */
    private _goToMainPage(): void {
        this._router.navigate(['/'], {replaceUrl: true});
    }

    /**
     * @function _isGdprAlreadyAccepted
     * @description
     * @private
     * @returns {boolean}
     */
    private _isGdprAlreadyAccepted(): boolean {
        const gdpr = localStorage.getItem('gdpr');
        const parsedValue = gdpr ? JSON.parse(gdpr) : false;
        return parsedValue;
    }
}
