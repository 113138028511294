export const environment = {
    env: 'releasepatch',
    production: true,
    serverUrl: 'https://bo.releasepatch.ocedetailing.com/kis',
    serverApiUrl: 'https://bo.releasepatch.ocedetailing.com/api',
    serverApiOTUrl: 'https://bo.releasepatch.ocedetailing.com/tokbox',
    authUrl: 'https://bo.releasepatch.ocedetailing.com/api/oauth',
    authClientId: '4jej02k21p4w8w48kww08gsg8gwckwsgkcg48oocgwkkwoso4k',
    sdkJsPlayer: 'assets/js/sdk.min.js',
    webSocketUrl: 'wss://xmpp-bo.releasepatch.ocedetailing.com:443/wss/',
    opentokApiKey : '46230622'
};
