// Core modules
import {AfterViewInit, Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';

// Third-party modules
import {fromEvent, ReplaySubject, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

// Internal modules
import {environment} from '@env/environment';

// Internal interfaces
import {XMPPmessage} from '@app/core/messaging-provider/abstract-message-provider';
import {SurveyNotification} from '@app/core/messaging/survey-notification';
import {PollAnswers} from '@app/core/messaging/poll-answers';
import {RevokeAccess} from '@app/core/messaging/revoke-access';
import {TouchGesture} from '@app/core/messaging/touch-gesture';
import {DrawAction} from '@app/core/messaging/draw-action';
import {Notification} from '@app/core/messaging/notification';
import {MessageInterface} from '@app/core/messaging/message';
import {Presence} from '@app/core/messaging/presence';
import {SessionRoomStatus} from '@app/home/session/session-room-status';
import {LoadSequence, LoadSlide} from '@app/core/messaging/load-slide';
import {VideoAction} from '@app/core/messaging/video-action';
import {SessionStatus, statePlayPause} from '@app/core/messaging/session-state';
import {SessionPlayPauseState} from '@app/core/messaging/session-play-pause-state';
import {SlideLoadedEvent} from '@app/core/messaging/slide-loaded-event';
import {InteractiveModeEnableCommand} from '@app/core/messaging/interactive-mode-enable-command';

// Internal models
import {Session} from '@app/shared/models/session';
import {Participant} from '@app/shared/models/participant';
import {Question} from '@app/shared/models/question';
import {Survey} from '@app/shared/models/survey';
import {User} from '@app/shared/models/user';

// Internal services
import {PresentationEvent, PresentationEventsService, PresentationResponse} from '@app/home/session/presentation/presentation-events.service';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {MessagingService} from '@app/core/messaging/messaging.service';
import {SDK_EVENTS, SdkService} from '@app/shared/service/sdk.service';
import {SessionStatusService} from '@app/shared/service/sessionStatus.service';
import {AttendeesService} from '@app/shared/service/attendees.service';
import {UserService} from '@app/shared/service/user.service';
import {HeaderService} from '@app/shell/header/header.service';
import {PollService} from '@app/shared/service/poll.service';
import {SessionService} from '@app/shared/service/session.service';
import {Logger} from '@app/core/logger.service';
import {FlashService} from '@app/shared/flash/flash.service';
import {UtilService} from '@app/shared/service/util.service';

// Global variables declaration
const logger = new Logger('Session');

@Component({
    selector: 'app-session',
    templateUrl: './session.component.html',
    styleUrls: ['./session.component.scss']
})
export class SessionComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {

    /**
     * Data members
     */
    @Input() session: Session;
    @Output() onRoomConnectionStatusChange = new EventEmitter<SessionRoomStatus>();
    @Output() onPresentationStatusChange = new EventEmitter<boolean>();
    public isLoading: boolean;
    public isVertical: boolean = false;
    public toggleConference: boolean = false;
    public showPresentation: boolean = false;
    public speakerPresent: boolean = false;
    public speaker: Participant;
    public pollAnswers: any = '';
    public isExternalUser: boolean = false;
    public showPoll: Boolean;
    public question: Question;
    public surveyID: number;
    private survey: Survey;
    private room: string;
    private roomStatus: SessionRoomStatus = SessionRoomStatus.Disconnected;
    private presentationMessages: ReplaySubject<MessageInterface> = new ReplaySubject<MessageInterface>();
    private _username: string;
    private _translations: string[] = [];
    private subscriptions: Subscription[] = [];
    private initConnection: boolean = false;
    private _currentPath: string;
    private sdk: any;
    private _presenterReconnectionTimer: any;
    private _nbSecBeforeLogout: number = 120;
    private _hasPresenterMode: boolean;
    private OCE_CONTENTS_PROXY: string = '/contentoce/';
    private DOP_CONTENTS_PROXY: string = '/contents/';
    private LOCAL_ENV_NAME: string = 'local';
    private inSessionSinceTimestamp: number;
    private sessionStateMsgReceived: boolean;
    private _nbSecBackWrIfNoSessionStateMsg: number = 30;

    /**
     * @function constructor
     * @param {TranslateService} _translateService
     * @param {AuthenticationService} _authService
     * @param {HeaderService} headerService
     * @param {Router} router
     * @param {MessagingService} _messagingService
     * @param {FlashService} flashService
     * @param {SessionService} sessionService
     * @param {PollService} pollService
     * @param {AttendeesService} _attendees
     * @param {UserService} _userService
     * @param {PresentationEventsService} _presentationActionsService
     * @param {UtilService} _utilService
     * @param {SdkService} _sdkService
     * @param {SessionStatusService} _sessionStatusService
     */
    constructor(
        private _translateService: TranslateService,
        private _authService: AuthenticationService,
        private headerService: HeaderService,
        private router: Router,
        private _messagingService: MessagingService,
        private flashService: FlashService,
        private sessionService: SessionService,
        private pollService: PollService,
        private _attendees: AttendeesService,
        private _userService: UserService,
        private _presentationActionsService: PresentationEventsService,
        private _utilService: UtilService,
        private _sdkService: SdkService,
        private _sessionStatusService: SessionStatusService
    ) {}

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        this._initTranslations();
        this.initMessagingSubscriptions();
        this.initPresentationSubscriptions();
        this.initView();
        this._authService.isExternalUser().then((bool: boolean) => {
            this.isExternalUser = bool;
        });
        this.showPoll = false;
        this.headerService.changeVisibilty(true);
        this.headerService.changeTitle(this.session.sessionName);
        this.headerService.backButtonActionChanged.subscribe(() => this.backToSessionList());
        this._username = this._authService.credentials.username;

        this.session.members.forEach((member: Participant) => {
            if (member.role === 'speaker') {
                this.speaker = member;
                this.sessionService.setSpeaker(member);
                this._userService.getUser(member.memberDN)
                    .subscribe((user: User) => {
                        this.speaker.first_name = user.first_name;
                        this.speaker.last_name = user.last_name;
                    });
            }
        });

        // Joining XMPP room
        this.room = 'r' + this.session.sessionKey + '@conference.' + this._authService.credentials.tenant;
        this._messagingService.joinChannel(this.room, this.speaker.memberDN);

        this.sessionService.getSession(this.session.id)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((data: Session) => this.session = data,
                (err) => console.log(err)
            );
    }

    /**
     * @function ngAfterViewInit
     */
    ngAfterViewInit() {
        this._sdkService.injectScript().then(() => {
            this.initPlayerJsSubscription();
        });
    }

    /**
     * @description reset connection (
     * @function ngDoCheck
     */
    ngDoCheck() {
        // Reinitializing connection and joining the room again if the connection has been lost accidentally
        if (!this._messagingService.isConnected() && !this.initConnection) {
            this.initConnection = true;
            this._messagingService.resetConnection(() => {
                    const room = 'r' + this.session.sessionKey + '@conference.' + this._authService.credentials.tenant;
                    this._messagingService.joinChannel(room, this.speaker.memberDN);
                    // init the connection boolean
                    this.initConnection = false;
                }
            );
        }

        // Checking during a laps of time we receive
        if (!this.sessionStateMsgReceived) {
            this.checkSessionStateReceived();
        }
    }

    /**
     * @function ngOnDestroy
     */
    ngOnDestroy() {
        const sdk = this._sdkService.sdk;
        // Sending a blank page when leaving the session before destroying the component
        // Work around for KIWEB-664 to avoid the last loaded slide from last presentation to appear
        // when accessing the first time in a new presentation (from a new session key)
        // Probably, an issue with ReplaySubject in events.service.ts from JS SDK
        sdk && sdk.getPlayerJS && sdk.getPlayerJS().eventDispatcher({ type: 'load-slide', size: { width: 1024, height: 768 }, url: 'about:blank' });

        this.subscriptions.forEach(
            (subscription: Subscription) => subscription.unsubscribe()
        );
        if (sdk && sdk.playerJS && sdk.playerJS.initEventObservable) {
            sdk.playerJS.initEventObservable();
        }
    }

    /**
     * @return void
     */
    private checkSessionStateReceived(): void {
        const date = new Date().getTime();
        const nbSecSinceEnteredPresentation = Math.abs(this.inSessionSinceTimestamp - date) / 1000;
        if (this.showPresentation && nbSecSinceEnteredPresentation >= this._nbSecBackWrIfNoSessionStateMsg) {
            this.toWaitingRoom();
            this.speakerPresent = false;
            this.sessionStateMsgReceived = false;
        }
    }

    /**
     * @return void
     */
    private initMessagingSubscriptions(): void {
        this.subscriptions.push(
            this._messagingService.Messages
                .subscribe((message: MessageInterface) => {
                    switch (message.constructor) {
                        case Presence:
                            this._presenceReceived(<Presence>message);
                            break;

                        case RevokeAccess:
                            this._revokeAccessReceived(<RevokeAccess>message);
                            break;

                        case Notification:
                            this.notificationReceived(<Notification>message);
                            const notification = <Notification>message;
                            if (notification.sessionId === this.session.id) {
                                this._sdkService.injectScript().then(() => {
                                    this._sdkService.sdk.getPlayerJS().eventDispatcher(notification);
                                });
                            }
                            break;

                        case SurveyNotification:
                            this._surveyNotificationReceived(<SurveyNotification>message);
                            break;

                        case PollAnswers:
                            this._pollAnswersReceived(<PollAnswers>message);
                            break;

                        case LoadSequence:
                            const dataSequenceOCE = <LoadSequence>message;
                            message.url = this._getContentUrl(dataSequenceOCE.sequenceURL);
                            this._sdkService.injectScript().then(() => {
                                this._sdkService.sdk.getPlayerJS().eventDispatcher(<LoadSequence>message);
                            });
                            break;

                        case LoadSlide:
                            const dataSlideOCE = <LoadSlide>message;
                            message.url = this._getContentUrl(dataSlideOCE.slideURL);
                            this._sdkService.injectScript().then(() => {
                                this._sdkService.sdk.getPlayerJS().eventDispatcher(<LoadSlide>message);
                            });
                            // Updating drawer
                            this._presentationActionsService.updateDrawerAction(dataSlideOCE);
                            break;

                        case SessionPlayPauseState:
                            const playPauseState = <SessionPlayPauseState>message;
                            // Redirecting participant if the presenter has closed the session properly
                            if (playPauseState.state === statePlayPause.close) {
                                this.logout(true);
                            }
                            // prepare url for the SDK JS  Player
                            // const sessionState = <SessionPlayPauseState>message;
                            this._sdkService.injectScript().then(() => {
                                this._sdkService.sdk.getPlayerJS().eventDispatcher(playPauseState);
                            });
                            break;

                        case SessionStatus:
                            const sessionState = <SessionStatus>message;
                            this.sessionStateMsgReceived = true;
                            this.inSessionSinceTimestamp = null;
                            this._sessionStatusService.sessionStatus = sessionState;

                            // Generating dynamic content
                            this._sessionStatusService.buildOCEDynamicContent();

                            const currentSlide = sessionState.currentSlide;
                            currentSlide.type = SDK_EVENTS.LOAD_SLIDE;
                            currentSlide.url = this._getContentUrl(sessionState.currentSlide.slideURL);

                            const playPauseStatus = new SessionPlayPauseState();
                            playPauseStatus.state = sessionState.state;
                            playPauseStatus.type = SDK_EVENTS.NOTIFICATION;

                            const relatedDocuments = sessionState.relatedDocuments;
                            this._presentationActionsService.remoteRelatedContentAction(relatedDocuments);

                            const isChatEnabled = sessionState.isChatEnabled;
                            this._presentationActionsService.isChatEnabled(isChatEnabled);

                            const isConferenceEnabled = sessionState.isConferenceEnabled;
                            this._presentationActionsService.isConferenceEnabled(isConferenceEnabled);

                            if (sessionState.isInteractiveModeEnabled) {
                                const InteractiveModeMessage = new InteractiveModeEnableCommand();
                                InteractiveModeMessage.type = 'interactive-mode-enable-command';
                                InteractiveModeMessage.isEnabled = sessionState.isInteractiveModeEnabled;
                                this._messagingService.ReplayMessages.next(InteractiveModeMessage);
                            }

                            if (sessionState.presentations) {
                                this._presentationActionsService.sessiondrawerInfo(sessionState.presentations);
                            }

                            if (sessionState.isHighlightingModeEnabled) {
                                this._sdkService.injectScript().then(() => {
                                    this._sdkService.sdk.getPlayerJS().eventDispatcher({
                                        type: 'drawing-mode',
                                        isEnabled: sessionState.isHighlightingModeEnabled,
                                        userID: this._authService.credentials.username
                                    });
                                });
                            }

                            this._sdkService.injectScript().then(() => {
                                this.startPresentation();
                                this._sdkService.sdk.getPlayerJS().eventDispatcher(<any>currentSlide);
                                this._sdkService.sdk.getPlayerJS().eventDispatcher(<SessionPlayPauseState>playPauseStatus);
                                if (currentSlide.videos) {
                                    const video: any = currentSlide.videos[0];
                                    const videoAction = new VideoAction();
                                    videoAction.currentTime = video.time;
                                    videoAction.eventType = video.state;
                                    videoAction.index = video.index;
                                    videoAction.type = 'video-action';
                                    this._sdkService.sdk.getPlayerJS().eventDispatcher(<VideoAction>videoAction);
                                }

                                // Updating drawer
                                setTimeout(() => {
                                    this._presentationActionsService.updateDrawerAction(currentSlide);
                                }, 500);
                            });
                            break;

                        case TouchGesture:
                            this._sdkService.injectScript().then(() => {
                                this._sdkService.sdk.getPlayerJS().eventDispatcher(<TouchGesture>message);
                            });
                            break;

                        case DrawAction:
                            this._sdkService.injectScript().then(() => {
                                const msg = <DrawAction>message;
                                const user = this._attendees.getAttendee(message.body.ie.u);
                                msg.userID = user && user.uid || '';
                                const firstname = user && user.first_name || '';
                                const lastname = user && user.last_name || '';
                                const tokenname = user && user.token_name || '';
                                msg.userName = (firstname || firstname) ? firstname + ' ' + lastname : tokenname;
                                this._sdkService.sdk.getPlayerJS().eventDispatcher(msg);
                            });
                            break;

                        case VideoAction:
                            this._sdkService.injectScript().then(() => {
                                this._sdkService.sdk.getPlayerJS().eventDispatcher(<VideoAction>message);
                            });
                            break;

                        default:
                            this._messageReceived(message);
                    }
                })
        );
    }

    /**
     * @return void
     */
    private initPresentationSubscriptions(): void {
        this.subscriptions.push(
            this._presentationActionsService.actionRequests.subscribe(
                (action: PresentationResponse) => {
                    switch (action.event) {
                        case PresentationEvent.ToWaitingRoom:
                            this.toWaitingRoom();
                            break;

                        case PresentationEvent.askForHandNotification:
                            if (action.data) {
                                this._hasPresenterMode = action.data;
                                // Resetting current path for participant who has just taken the hand over
                                this._currentPath = null;
                            }
                            break;

                        case PresentationEvent.closePoll:
                            this.showPollAction(false);
                            break;
                    }
                }
            )
        );
    }

    /**
     * @return void
     */
    private initPlayerJsSubscription(): void {
        this.sdk = this._sdkService.sdk;
        this.sdk.getPlayerJS().eventObservable.subscribe((message: any) => {
            switch (message.type) {
                case SDK_EVENTS.SLIDE_LOADED_EVENT :
                    const slideEvent = new SlideLoadedEvent();
                    slideEvent.presentationIdentifier = message.data.presentationIdentifier;
                    slideEvent.sequenceIdentifier = message.data.sequenceIdentifier;
                    slideEvent.slideName = message.data.slideName;
                    this._messagingService.slideLoadedNotification(slideEvent);
                    break;

                case SDK_EVENTS.TOUCH_EVENT:
                    this._messagingService.sendTouch(message.eventType, message.x, message.y);
                    break;

                case SDK_EVENTS.VIDEO_EVENT:
                    this._messagingService.sendVideoAction(message.eventType, message.i, message.ct);
                    break;

                case SDK_EVENTS.DRAWING_EVENT_NOTIFICATION:
                    this._messagingService.sendDrawingAction(message);
                    break;

                case SDK_EVENTS.DRAWING_ERASE_NOTIFICATION:
                    this._messagingService.sendEraseDrawingAction();
                    break;
            }
        });
    }

    /**
     * @return void
     */
    private initView(): void {
        this.isVertical = window.innerHeight > window.innerWidth;
        this.subscriptions.push(
            fromEvent(window, 'resize')
                .subscribe((event: Event) => {
                    this.isVertical = window.innerHeight > window.innerWidth;
                })
        );
    }

    /**
     * @function _getContentUrl
     * @description
     * @private
     * @param {string} url
     * @returns {string}
     */
    private _getContentUrl(url: string): string {
        if (environment.env === this.LOCAL_ENV_NAME && environment.env === 'local') {
            const urlBase = document.location.origin + this.OCE_CONTENTS_PROXY;
            let filePath = '';
            const urlTab = url && url.split('/');
            for (let i = 4, length = urlTab.length; i < length; i++) {
                filePath += urlTab[i] + '/';
            }
            return urlBase + filePath.substring(0, filePath.length - 1);
        } else {
            return url;
        }
    }

    /**
     * @function logout
     * @description
     * @public
     * @param {boolean} force
     * @returns {void}
     */
    public logout(force: boolean = false): void {
        if (force) {
            this._utilService._doLogout();
        } else if (confirm(this._translations['leave_session_confirm'])) {
            this._utilService._doLogout();
        }
        this._stopCheckingPresenterReconn();
    }

    /**
     * @function startPresentation
     * @description
     * @public
     * @returns {void}
     */
    public startPresentation(): void {
        if (!this.speakerPresent) {
            logger.warn('The speaker is not available yet.');
            return;
        }

        if (!this._sdkService.sdk) {
            logger.warn('The JS SDK is not loaded yet.');
            return;
        }

        this.showPresentation = true;
        this.inSessionSinceTimestamp = new Date().getTime();
        this.onPresentationStatusChange.emit(true);
        setTimeout(() => this._messagingService.askForCurrentSlide(), 500);
    }

    /**
     * @function toWaitingRoom
     * @description
     * @public
     * @returns {void}
     */
    public toWaitingRoom(): void {
        this.showPresentation = false;
        this.headerService.changeVisibilty(true);
        this.onPresentationStatusChange.emit(false);
    }

    /**
     * @function backToSessionList
     * @description
     * @public
     * @returns {void}
     */
    public backToSessionList(): void {
        this.headerService.changeVisibilty(true);
        this._messagingService.leaveRoom(this.room);
        this.roomStatus = SessionRoomStatus.Disconnected;
        this.onRoomConnectionStatusChange.emit(this.roomStatus);
        this._attendees.deleteAttendee(this._username);
        this.sessionService.leaveSession(this.session.id).subscribe(() => {});
    }

    /**
     * @function showFullScreenButton
     * @description
     * @public
     * @returns {boolean}
     */
    public showFullScreenButton(): boolean {
        const elem: any = document.getElementById('content-container');
        return typeof elem.requestFullscreen !== 'undefined' || typeof elem.msRequestFullscreen !== 'undefined'
            || typeof elem.mozRequestFullScreen !== 'undefined' || typeof elem.webkitRequestFullscreen !== 'undefined';
    }

    /**
     * @function showPollAction
     * @description
     * @public
     * @param {boolean} value
     * @returns {void}
     */
    public showPollAction(value: boolean): void {
        this.showPoll = value;
    }

    /**
     * @function _initTranslations
     * @description
     * @private
     * @returns {void}
     */
    private _initTranslations(): void {
        this._translateService.get('Are you sure you want to leave the presentation?')
            .subscribe((trans: string) => this._translations['leave_session_confirm'] = trans);
    }

    /**
     * @function _revokeAccessReceived
     * @description
     * @private
     * @param {RevokeAccess} message
     * @returns {void}
     */
    private _revokeAccessReceived(message: RevokeAccess): void {
        if (message.jidToRevoke !== this._authService.credentials.connection_id) {
            return;
        }

        this._authService.isExternalUser().then((bool: boolean) => {
            if (bool && message.from) {
                this._authService.logout().subscribe(
                    () => {
                        this.router.navigate(['/login'], {replaceUrl: true}).then(
                            (data) => this.flashService.error(message.reason)
                        );
                    },
                    (err) => console.error(err)
                );
            } else {
                this.flashService.error(message.reason);
                this.backToSessionList();
            }
        });
    }

    /**
     * @function _presenceReceived
     * @description
     * @private
     * @param {Presence} message
     * @returns {void}
     */
    private _presenceReceived(message: Presence): void {
        if (message.error) {
            return;
        }

        if (this.roomStatus !== SessionRoomStatus.Connected) {
            this.roomStatus = SessionRoomStatus.Connected;
            this.onRoomConnectionStatusChange.emit(this.roomStatus);
        }

        // Updating list of attendees
        if (message.uid && message.uid.indexOf('api_user') === -1) {
            if (message.type === XMPPmessage.PRESENSE_UNAVAILABLE) {
                this._attendees.deleteAttendee(message.uid);
            } else if (!this._attendees.getAttendee(message.uid)) {
                // this._attendees.setAttendees()
                this._userService.getUser(message.uid)
                    .subscribe((user: User) => {
                        if (!this._attendees.getAttendee(message.uid)) {
                            this._attendees.setAttendees(user);
                        }
                    });
            }
        }

        if (this.speaker) {
            // Checking if we got a message from the speaker
            if (message.from.toLowerCase() === `${this.room}/${this.speaker.memberDN}`) {
                if (message.type === XMPPmessage.PRESENSE_UNAVAILABLE) {
                    this.speakerPresent = false;
                    if (this.isExternalUser) {
                        // The participant stays in the presentation till the presenter returns
                        // or is logged out after some presenter absence
                        this._startCheckingPresenterReconnAndLogout();
                    } else {
                        this.showPresentation = false;
                        this.headerService.changeVisibilty(true);
                        this.onPresentationStatusChange.emit(false);
                    }
                } else {
                    // Getting the hand over back to the presenter when he comes back if the participant still got it
                    if (this._hasPresenterMode) {
                        this._messagingService.leaveHand();
                    }
                    this.speakerPresent = true;
                    this._stopCheckingPresenterReconn();
                    this.startPresentation();
                }
            }
        }
    }

    /**
     * @function _startCheckingPresenterReconnAndLogout
     * @description
     * @private
     * @returns {void}
     */
    private _startCheckingPresenterReconnAndLogout(): void {
        const nbSecWarningDisplayed = 10;
        const nbSecBeforeWarning = this._nbSecBeforeLogout * 1000 - nbSecWarningDisplayed * 1000;
        this._presenterReconnectionTimer = setTimeout(() => {
            // Displaying message if the speaker is not present AND the application is still connected to XMPP
            // We do not want the message to be displayed when leaving the application by ourself, if it happens while the presenter disappeared for a while
            if (!this.speakerPresent && this._messagingService.isConnected()) {
                this._translateService.get('Presenter did not return after being disconnected', { x: this._nbSecBeforeLogout })
                    .subscribe((trans: string) => {
                        this.flashService.warning(trans, nbSecWarningDisplayed * 1000, true);
                    });
                setTimeout(() => this.logout(true), nbSecWarningDisplayed * 1000);
            }
        }, nbSecBeforeWarning);
    }

    /**
     * @function _stopCheckingPresenterReconn
     * @description
     * @private
     * @returns {void}
     */
    private _stopCheckingPresenterReconn(): void {
        if (this._presenterReconnectionTimer) {
            clearTimeout(this._presenterReconnectionTimer);
        }
    }

    /**
     * @function notificationReceived
     * @description
     * @public
     * @param {Notification} message
     * @returns {void}
     */
    private notificationReceived(message: Notification): void {
        if (message.sessionId === this.session.id) {
            this.session.sessionState = message.state;
            this.sessionService.getSession(this.session.id).subscribe(
                (session: Session) => {
                    this.session.audioType = session.audioType;
                    this.startPresentation();
                },
                (err) => logger.error(err)
            );
            return;
        }
    }

    /**
     * @function surveyNotificationReceived
     * @description
     * @private
     * @param {SurveyNotification} message
     * @returns {void}
     */
    private _surveyNotificationReceived(message: SurveyNotification): void {
        if (message.status === Survey.OPENED && message.sessionId === this.session.id) {
            this.pollService.getQuestionData(message.questionID)
                .subscribe((question: Question) => {
                    this.question = question;
                    this.surveyID = message.id;
                    this.showPoll = true;
                });
        } else if (message.status === Survey.CLOSED && message.sessionId === this.session.id) {
            this._presentationActionsService.closePollAction();
            this.pollAnswers = null;
        }
        if (message.body.notify.survey.status === Survey.CANCELLED) {
            this.showPoll = false;
            this.pollAnswers = null;
        }
    }

    /**
     * @function _pollAnswersReceived
     * @description
     * @private
     * @param {PollAnswers} message
     * @returns {void}
     */
    private _pollAnswersReceived(message: PollAnswers): void {
        if (message.status === Survey.OPENED) {
            this.showPoll = true;
            this.pollAnswers = message.answers;
        }
    }

    /**
     * @function _messageReceived
     * @private
     * @param {MessageInterface} message
     * @returns {void}
     */
    private _messageReceived(message: MessageInterface): void {
        if (!this.showPresentation) {
            return;
        }
        this.presentationMessages.next(message);
    }

}
