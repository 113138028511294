// Core modules
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

// Third-party modules
import {Observable} from 'rxjs';

// Internal services
import {AuthenticationService} from '@app/core/authentication/authentication.service';

@Injectable()
export class AuthInterceptor {

    /**
     * @function constructor
     * @param _authService
     */
    constructor(
        private _authService: AuthenticationService
    ) {}

    /**
     * @function intercept
     * @description
     * @public
     * @param {HttpRequest<any>} request
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     */
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this._authService.credentials) {
            return next.handle(request);
        }
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this._authService.credentials.access_token}`
            }
        });
        return next.handle(request);
    }

}
