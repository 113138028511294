// Internal interfaces
import {MessageInterface} from '@app/core/messaging/message';
import {SlideLoadedEvent} from '@app/core/messaging/slide-loaded-event';
import {CurrentSlide} from '@app/core/messaging/current-slide';

export abstract class AbstractMessageProvider {

    abstract connect(): void;
    abstract disconnect(): void;
    abstract joinRoom(channelId: string, username: string): void;
    abstract leaveRoom(channelId: string, username: string): void;
    abstract onConnected(callback: () => void): void;
    abstract onDisconnect(callback: () => void): void;
    abstract isConnected(): boolean;
    abstract resetConnection(callback: () => void): void;
    abstract sendMessage(message: MessageInterface): void;
    abstract onMessageReceived(callback: (message: MessageInterface) => void): void;
    abstract askForCurrentSlide(): void;
    abstract sendTouch(eventType: string, currentPositionX: number, currentPositionY: number, JID: string): void;
    abstract sendVideoAction(eventType: string, index: number, currentTime: number, JID: string): void;
    abstract sendDrawingAction(message: any): void;
    abstract sendEraseDrawingAction(): void;
    abstract askForHand(speakerUid: string): void;
    abstract leaveHand(presenterUID: string): void;
    abstract requestAudioVideoStreaming(speakerUid: string): void;
    abstract sendPresence(to: string, type: string): void;
    abstract isConnection(): boolean;
    abstract mandatoryFileNotification(name: string, status: string): void;
    abstract slideLoadedNotification(slideEvent: SlideLoadedEvent): void;
    abstract presentationSlideLoadCommand(slide: CurrentSlide): void;

}

export enum XMPPmessage {
    MAX_RETRY_CONNEXION = 3,
    PRESENSE_UNAVAILABLE = 'unavailable'
}
