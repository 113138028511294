// Core modules
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

// Internal components
import {HomeComponent} from './home.component';

// Internal services
import {Route} from '@app/core/route.service';
import {extract} from '@app/core/i18n.service';

// Global variables declaration
const routes: Routes = Route.withShell([
    {path: '', redirectTo: '/sessions', pathMatch: 'full'},
    {path: 'sessions', component: HomeComponent, data: {title: extract('APP_NAME')}}
]);

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class HomeRoutingModule {}
