// Core modules
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

// Third-party modules
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import * as OT from '@opentok/client';

// Internal modules
import {environment} from '@env/environment';

// Internal services
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {BrowserService} from '@app/shared/service/browser.service';
import {Logger} from '@app/core/logger.service';
import Session = OT.Session;
import SubscriberProperties = OT.SubscriberProperties;
import OTError = OT.OTError;
import PublisherProperties = OT.PublisherProperties;

// Global variables declaration
const logger = new Logger('Opentok Service');

export enum OpentokConnectionStatus {
    Disabled = 'disabled',
    Connecting = 'connecting',
    Connected = 'connected',
    Disconnected = 'disconnected',
    Reconnecting = 'reconnecting',
    Reconnected = 'reconnected',
    PublishingError = 'publish_error',
    Error = 'error',
    DeniedAudioVideo = 'denied_audio_video',
    ConnectionIssue = 'connection_issue',
    FirewallIssue = 'firewall_issue'
}

@Injectable()
export class OpentokService {

    /**
     * Data members
     */
    public conferenceEvents: Subject<OpentokConnectionStatus> = new Subject();
    public conferenceStatus: OpentokConnectionStatus = OpentokConnectionStatus.Disabled;
    public publisher: OT.Publisher;
    public publishing: boolean;
    public streams: Array<OT.Stream> = [];
    private session: OT.Session = null;
    private token: string = null;
    private _translations: string[] = [];
    public deniedAVAccess = false;
    public isConnectionLost = false;

    /**
     * @function constructor
     * @param {AuthenticationService} authService
     * @param {HttpClient} httpClient
     * @param {BrowserService} _browserService
     * @param {TranslateService} _translateService
     */
    constructor(
        private authService: AuthenticationService,
        private httpClient: HttpClient,
        private _browserService: BrowserService,
        private _translateService: TranslateService
    ) {
        this._translateService.get('not supported browser version for OpenTok')
            .subscribe((trans: string) => this._translations['not supported browser version for OpenTok'] = trans);
    }

    /**
     * @function getOT
     * @description
     * @public
     * @returns {any}
     */
    public getOT(): any {
        return OT;
    }

    /**
     * @function resetSession
     * @description
     * @public
     * @returns {void}
     */
    public resetSession(): void {
        this.token = null;
        this.session = null;
    }

    /**
     * @function resetSession
     * @description
     * @public
     * @param {string} key
     * @param {boolean} isPresenter
     * @returns {Observable<Session>}
     */
    public initSession(key: string, isPresenter: boolean = false): Observable<Session> {
        // key parameter can be the sessionId or the sessionKey, depending on the OCE status
        const uid: string = this.authService.credentials.username;
        const url = environment.serverApiOTUrl + '/session/key/' + key + '/token/' + uid + '?presenter=' + (isPresenter ? 'true' : 'false');
        return this.httpClient.get(url)
            .pipe(map((data: any) => {
                this.session = this.getOT().initSession(environment.opentokApiKey, data.tokSession);
                this.token = data.token;
                this.initEventHandlers();
                return this.session;
            }));
    }

    /**
     * @function connect
     * @description
     * @public
     * @returns {any}
     */
    public connect(): any {
        this.publishing = false;
        this.conferenceStatus = OpentokConnectionStatus.Connecting;
        return new Promise((resolve, reject) => {
            this.session && this.session.connect(this.token, (err) => {
                if (!!err) {
                    if (err.name === "OT_NOT_CONNECTED" || err.name === "OT_TIMEOUT" || err.name === "OT_UNEXPECTED_SERVER_RESPONSE" || err.name === "OT_CONNECT_FAILED")  {
                        this.isConnectionLost = true;
                        this.conferenceEvents.next(OpentokConnectionStatus.ConnectionIssue);
                    }
                    else {
                        logger.error(err);
                        this.conferenceStatus = OpentokConnectionStatus.Error;
                    }
                    return;
                }
                resolve(this.session);
                this.conferenceEvents.next(OpentokConnectionStatus.Connected);

                const uiOT = this.session.connection && this.session.connection.data && this.session.connection.data.substring(4);
                const uiXMPP = this.authService.credentials && this.authService.credentials.username;
                if (uiOT !== uiXMPP) {
                    let text = 'Warning: your OpenTok uid is different from your XMPP uid!';
                    text += '\nOpenTok uid: ' + uiOT;
                    text += '\n   XMPP uid: ' + uiXMPP;
                    console.error(text);
                }
            });
        });
    }

    /**
     * @function disable
     * @description
     * @public
     * @returns {void}
     */
    public disable(): void {
        this.streams = [];
        if (this.session) {
            this.session.disconnect();
        }
        this.conferenceStatus = OpentokConnectionStatus.Disabled;
        this.conferenceEvents.next(OpentokConnectionStatus.Disabled);
        this.publishing = false;
        logger.info('Disabled');
    }

    /**
     * @function startPublishing
     * @description
     * @public
     * @returns {void}
     */
    public startPublishing(): void {
        logger.info('Init Publishing');
        /*if(this.conferenceStatus !== OpentokConnectionStatus.Connected) {
            logger.info('Cant publish => not connected');
            return;
        }*/
        let name = (this.authService.credentials.user.first_name) ? this.authService.credentials.user.first_name : '';
        name += ' ' + this.authService.credentials.user.last_name;

        const publisherOptions: PublisherProperties = {
            width: 133,
            height: 100,
            name: name,
            publishAudio: true,
            publishVideo: false,
            // audioSource : mediaStream.getAudioTracks()[0].clone(),
            // videoSource : mediaStream.getVideoTracks()[0].clone(),
            insertMode: 'append'
        };
        this.publisher = OT.initPublisher(document.getElementById('kad-conference-publisher'), publisherOptions);

            this.session.publish(this.publisher, (err) => {
                if (err) {
                    logger.info('Error while Publishing');
                    logger.error(err.message);
                    if (err.name === "OT_NOT_CONNECTED" || err.name === "OT_TIMEOUT" || err.name === "OT_UNEXPECTED_SERVER_RESPONSE") {
                        this.isConnectionLost = true;
                        this.conferenceEvents.next(OpentokConnectionStatus.ConnectionIssue);
                    }else if (err.name === 'OT_CREATE_PEER_CONNECTION_FAILED') {
                        this.conferenceEvents.next(OpentokConnectionStatus.FirewallIssue);
                    }
                    else {
                        logger.error(err);
                    }
                    this.stopPublishing();
                   // this.conferenceEvents.next(OpentokConnectionStatus.PublishingError);
                } else {
                    this.publishing = true;
                    logger.info('Started Publishing');
                }
            });
            this.publisher.on('accessDenied', this.accessDenied.bind(this));
            this.publisher.on('streamDestroyed', this.publisherStreamDestroyed.bind(this));


    }

    /**
     * @function stopPublishing
     * @description
     * @public
     * @returns {void}
     */
    public stopPublishing(): void {
        if (this.isPublishingAudio()) {
            this.publisher.publishAudio(false);
        }
        if (this.isPublishingVideo()) {
            this.publisher.publishVideo(false);
        }

        logger.info('Stop Publishing');
        setTimeout(() => {
            this.publishing = false;
            if (this.publisher && this.session) {
                this.session.unpublish(this.publisher);
                this.publisher.destroy();
                this.publisher = null;
            }
        }, 1000);
    }

    /**
     * @function isPublishingAudio
     * @description
     * @public
     * @returns {boolean}
     */
    public isPublishingAudio(): boolean {
        if (!this.publishing) {
            return false;
        }
        return this.publisher.stream && this.publisher.stream.hasAudio;
    }

    /**
     * @function isPublishingVideo
     * @description
     * @public
     * @returns {boolean}
     */
    public isPublishingVideo(): boolean {
        if (!this.publishing) {
            return false;
        }
        return this.publisher.stream && this.publisher.stream.hasVideo;
    }

    /**
     * @function muteUnmutePublisher
     * @description
     * @public
     * @returns {void}
     */
    muteUnmutePublisher(): void {
        if (!this.publishing || this.conferenceStatus !== OpentokConnectionStatus.Connected) {
            return;
        }

        this.publisher.publishAudio(!this.isPublishingAudio());
        logger.info('Enable publisher audio = ' + this.isPublishingAudio());
        /* this.conferenceEvents.next(OpentokConnectionStatus.Disabled);
        this._eventsService.streamingDisabledNotify();
        this.stopPublishing(); */
    }

    /**
     * @function togglePublisherVideo
     * @description
     * @public
     * @returns {void}
     */
    togglePublisherVideo(): void {
        if (!this.publishing || this.conferenceStatus !== OpentokConnectionStatus.Connected) {
            return;
        }

        this.publisher.publishVideo(!this.isPublishingVideo());
        logger.info('Enable publisher video = ' + this.isPublishingVideo());
    }

    /**
     * @function isPublishing
     * @description
     * @public
     * @returns {boolean}
     */
    public isPublishing(): boolean {
        return this.publishing;
    }


    /**
     * @function isConnectionLost
     * @description
     * @public
     * @returns {boolean}
     */
    public isConnLost(): boolean {
        return this.isConnectionLost;
    }


    /**
     * @function initEventHandlers
     * @description
     * @public
     * @returns {void}
     */
    private initEventHandlers(): void {
        this.session.on('streamCreated', this.streamCreated.bind(this));
        this.session.on('streamPropertyChanged', this.streamPropertyChanged.bind(this));
        this.session.on('streamDestroyed', this.streamDestroyed.bind(this));
        this.session.on('sessionDisconnected', this.sessionDisconnected.bind(this));
        this.session.on('sessionReconnecting', this.sessionReconnecting.bind(this));
        this.session.on('sessionReconnected', this.sessionReconnected.bind(this));
        this.session.on('sessionConnected', this.sessionConnected.bind(this));
        this.session.on('connectionDestroyed', this.connectionDestroyed.bind(this));
    }

    /**
     * @function streamCreated
     * @description
     * @private
     * @param {any} event
     * @returns {void}
     */
    private streamCreated(event: any): void {
        if (this.streams.indexOf(event.stream) >= 0) {
            return;
        }
        logger.info('Stream created');

        this.streams.push(event.stream);
        this.isConnectionLost = false;
        const subscriberOptions: SubscriberProperties = {
            width: 133,
            height: 100,
            // testNetwork: true => problems when activated for iphone safari
        };
        setTimeout(() => {
            this.session.subscribe(
                event.stream,
                document.getElementById('kad-conference-' + event.stream.streamId),
                subscriberOptions,
                (err: OTError) => {

                    if (!!err) {
                        logger.error('Error while subscribing to publisher', err);
                        if (err.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
                            // Access denied can also be handled by the accessDenied event
                            logger.error('Please allow access to the Camera and Microphone and try publishing again.');
                        } else {
                            logger.error('Failed to get access to your camera or microphone. Please check that your webcam is connected and not being used by another application and try again.');
                        }
                        this.stopPublishing();
                        return;
                    }

                    logger.info('Subscribed to the stream "' + event.stream.name + '"');
                    this.conferenceEvents.next(OpentokConnectionStatus.Connected);
                }
            );
        }, 200);
    }

    /**
     * @function streamDestroyed
     * @description
     * @private
     * @param {any} event
     * @returns {void}
     */
    private streamDestroyed(event: any): void {
        const idx = this.streams.indexOf(event.stream);
        logger.info(event.stream.name + ' : Stream destroyed ');

        if (idx > -1) {
            this.streams.splice(idx, 1);
        }
    }

    /**
     * @function streamPropertyChanged
     * @description
     * @private
     * @param {any} event
     * @returns {void}
     */
    private streamPropertyChanged(event: any): void {
        logger.info('"' + event.stream.name + '" Stream changed => Video : ' + event.stream.hasVideo + ', Audio : ' + event.stream.hasAudio);
        this.conferenceEvents.next(OpentokConnectionStatus.Connected);
    }

    /**
     * @function sessionDisconnected
     * @description
     * @private
     * @param {any} event
     * @returns {void}
     */
    private sessionDisconnected(event: any): void {
        this.stopPublishing();
        this.conferenceStatus = OpentokConnectionStatus.Disconnected;
        this.conferenceEvents.next(OpentokConnectionStatus.Disconnected);
        logger.info('Session disconnected');
    }

    /**
     * @function sessionReconnecting
     * @description
     * @private
     * @param {any} event
     * @returns {void}
     */
    private sessionReconnecting(event: any): void {
        logger.info('Session reconnecting');
        this.conferenceStatus = OpentokConnectionStatus.Reconnecting;
        this.conferenceEvents.next(OpentokConnectionStatus.Reconnecting);
    }

    /**
     * @function sessionReconnected
     * @description
     * @private
     * @param {any} event
     * @returns {void}
     */
    private sessionReconnected(event: any): void {
        logger.info('Session reconnected');
        this.conferenceStatus = OpentokConnectionStatus.Connected;
        this.conferenceEvents.next(OpentokConnectionStatus.Reconnected);
    }

    /**
     * @function sessionConnected
     * @description
     * @private
     * @param {any} event
     * @returns {void}
     */
    private sessionConnected(event: any): void {
        logger.info('Session connected');
        this.conferenceStatus = OpentokConnectionStatus.Connected;
        this.conferenceEvents.next(OpentokConnectionStatus.Connected);
    }

    /**
     * @function connectionDestroyed
     * @description
     * @private
     * @param {any} event
     * @returns {void}
     */
    private connectionDestroyed(event: any): void {
        logger.info('connectionDestroyed');
    }

    /**
     * @function accessDenied
     * @description
     * @private
     * @param {any} event
     * @returns {void}
     */
    private accessDenied(event: any): void {
        this.deniedAVAccess = true;
        this.conferenceEvents.next(OpentokConnectionStatus.DeniedAudioVideo);
    }


    /**
     * @function publisherStreamDestroyed
     * @description
     * @private
     * @param {any} event
     * @returns {void}
     */
    private publisherStreamDestroyed(event: any): void {
        if (event.reason === 'networkDisconnected') {
            this.isConnectionLost = true;
            this.conferenceEvents.next(OpentokConnectionStatus.ConnectionIssue);
        }
    }

}
