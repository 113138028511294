// Core modules
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ResizableModule} from 'angular-resizable-element';

// Third-party modules
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

// Internal components
import {LoaderComponent} from '@app/shared/loader/loader.component';
import {FlashComponent} from '@app/shared/flash/flash.component';

// Internal services
import {FlashService} from '@app/shared/flash/flash.service';

// Internal directives
import {AutofocusDirective} from '@app/shared/directive/AutofocusDirective';
import {ClickOutsideDirective} from '@app/shared/directive/clickOutsideDirective';

// Internal pipes
import {SafeUrlPipe} from '@app/shared/pipes/safeUrl.pipe';
import {SafeHtmlPipe} from '@app/shared/pipes/safeHtml.pipe';
import {FilterPipe} from '@app/shared/pipes/filter.pipe';
import {FilterTitlePipe} from '@app/shared/pipes/filterTitle.pipe';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        ResizableModule
    ],
    declarations: [
        LoaderComponent,
        SafeUrlPipe,
        SafeHtmlPipe,
        FlashComponent,
        AutofocusDirective,
        ClickOutsideDirective,
        FilterPipe,
        FilterTitlePipe
    ],
    exports: [
        LoaderComponent,
        FlashComponent,
        SafeUrlPipe,
        SafeHtmlPipe,
        FilterPipe,
        FilterTitlePipe,
        ResizableModule,
        AutofocusDirective,
        ClickOutsideDirective
    ],
    providers: [
        FlashService
    ],
})
export class SharedModule {
}
