// Core modules
import {Component, HostListener, OnInit} from '@angular/core';

// Third-party modules
import {TranslateService} from '@ngx-translate/core';

// Internal services
import {I18nService} from '@app/core/i18n.service';
import {CustoService} from '@app/shared/service/custo.service';
import {BrowserService} from '@app/shared/service/browser.service';

@Component({
    selector: 'app-gdpr',
    templateUrl: './gdpr.component.html',
    styleUrls: ['./gdpr.component.scss']
})
export class GdprComponent implements OnInit {

    /**
     * Data members
     */
    public CUSTO: any = {};
    public isDesktop: boolean;
    private _translations: string[] = [];

    /**
     * @function onResize
     * @description
     * @public
     * @returns {void}
     */
    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this._resizeGdprContainer();
    }

    /**
     * @function constructor
     * @param {I18nService} _i18nService
     * @param {TranslateService} _translateService
     * @param {CustoService} _custoService
     * @param {BrowserService} _browserService
     */
    constructor(
        private _i18nService: I18nService,
        private _translateService: TranslateService,
        private _custoService: CustoService,
        private _browserService: BrowserService
    ) {
        if (this._browserService.isDesktop() && document.body.clientWidth > 823) {
            this.isDesktop = true;
        }
        this._initTranslations();
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        setTimeout(() => {
            this._resizeGdprContainer();
        });
        this._initCusto();
    }

    /**
     * @function _initTranslations
     * @description
     * @private
     * @returns {void}
     */
    private _initTranslations(): void {
        this._translateService.get('Client\'s privacy policy')
            .subscribe((trans: string) => this._translations['clients_privacy_policy'] = trans);
    }

    /**
     * @function _initCusto
     * @description
     * @private
     * @param {string} locale
     * @returns {void}
     */
    private _initCusto(locale: string = null): void {
        locale = !locale ? this._i18nService.language : locale;
        this.CUSTO['loginPageGdprTabName'] = this._custoService.getProp('loginPageGrpdTabName', locale) || this._translations['clients_privacy_policy'];
        this.CUSTO['loginPageGdprText'] = this._custoService.getProp('loginPageGrpdText', locale);
    }

    /**
     * @function displayGdprIQVIA
     * @description
     * @public
     * @returns {void}
     */
    public displayGdprIQVIA(): void {
        const clientGdprMenu = document.getElementById('client-gdpr-menu');
        const iqviaGdprMenu = document.getElementById('iqvia-gdpr-menu');
        const clientGdprContent = document.getElementById('client-gdpr-content');
        const iqviaGdprContent = document.getElementById('iqvia-gdpr-content');

        clientGdprMenu.classList.remove('active');
        iqviaGdprMenu.classList.add('active');
        clientGdprContent.classList.remove('active');
        iqviaGdprContent.classList.add('active');
    }

    /**
     * @function displayGdprClient
     * @description
     * @public
     * @returns {void}
     */
    public displayGdprClient(): void {
        const clientGdprMenu = document.getElementById('client-gdpr-menu');
        const iqviaGdprMenu = document.getElementById('iqvia-gdpr-menu');
        const clientGdprContent = document.getElementById('client-gdpr-content');
        const iqviaGdprContent = document.getElementById('iqvia-gdpr-content');

        clientGdprMenu.classList.add('active');
        iqviaGdprMenu.classList.remove('active');
        clientGdprContent.classList.add('active');
        iqviaGdprContent.classList.remove('active');
    }

    /**
     * @function _resizeGdprContainer
     * @description
     * @private
     * @returns {void}
     */
    private _resizeGdprContainer(): void {
        const gdprNav = document.getElementById('gdpr-nav');
        const gdprNavOffsetHeight = gdprNav && gdprNav.offsetHeight || 0;
        const gdprContentWrapper = document.getElementById('gdpr-content-wrapper');
        let windowHeight = 0;
        this._browserService.isIos() ? windowHeight = window.document.body.clientHeight : windowHeight = window.innerHeight;
        gdprContentWrapper.style.height = (windowHeight - gdprNavOffsetHeight) + 'px';
    }

}
