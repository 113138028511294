// Core modules
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

// Third-party modules
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

// Internal modules
import {environment} from '@env/environment';

// Internal models
import {Session} from '@app/shared/models/session';
import {Participant} from '@app/shared/models/participant';

@Injectable()
export class SessionService {

    /**
     * Data members
     */
    private _speaker: Participant = null;

    /**
     * @function constructor
     * @param {HttpClient} http
     */
    constructor(
        private http: HttpClient
    ) {}

    /**
     * @function getSessions
     * @description
     * @public
     * @returns {Observable<Session[]>}
     */
    getSessions(): Observable<Session[]> {
        return this.http.get(environment.serverUrl + '/sessionservice/v2/sessions')
            .pipe(
                map((res) => res['Sessions']['sessionList']),
                map((body: Session[]) => body)
            );
    }

    /**
     * @function getSessions
     * @description
     * @public
     * @param {number} sessionId
     * @returns {Observable<Session>}
     */
    getSession(sessionId: number): Observable<Session> {
        return this.http.get(environment.serverUrl + '/sessionservice/v2/sessions/' + sessionId)
            .pipe(
                map((res) => res['Session']),
                map((body: Session) => body)
            );
    }

    /**
     * @function leaveSession
     * @description
     * @public
     * @param {number} sessionsId
     * @returns {void}
     */
    leaveSession(sessionsId: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            })
        };
        return this.http.put(environment.serverUrl + '/sessionservice/sessions/' + sessionsId + '/leave', {}, httpOptions);
    }

    /**
     * @function setSpeaker
     * @description
     * @public
     * @param {Participant} member
     * @returns {void}
     */
    public setSpeaker(member: Participant): void {
        this._speaker = member;
    }

    /**
     * @function getSpeakerJid
     * @description
     * @public
     * @returns {string}
     */
    public getSpeakerJid(): string {
        return this._speaker.memberDN;
    }

    /**
     * @function isSpeaker
     * @description
     * @public
     * @param {string} jid
     * @returns {boolean}
     */
    public isSpeaker(jid: string): boolean {
        return this._speaker.memberDN === jid;
    }

}
