// Core modules
import {Injectable, OnDestroy} from '@angular/core';

// Third-party modules
import {ReplaySubject, Subject, Subscription} from 'rxjs';

// Internal interfaces
import {MessageInterface} from '@app/core/messaging/message';
import {AbstractMessageProvider} from '@app/core/messaging-provider/abstract-message-provider';
import {SlideLoadedEvent} from '@app/core/messaging/slide-loaded-event';
import {CurrentSlide} from '@app/core/messaging/current-slide';

// Internal services
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {Logger} from '@app/core/logger.service';

// Global variable declaration
const logger = new Logger('Messaging Service');

export interface MessagingConnectionStatus {
    connected: boolean;
}

@Injectable()
export class MessagingService implements OnDestroy {

    /**
     * Data members
     */
    private currentChannel: any = null;
    private username: string = null;
    private presenterUid: string = null;
    private _messages: Subject<MessageInterface> = new Subject<MessageInterface>();
    private _replayMessages: ReplaySubject<MessageInterface> = new ReplaySubject<MessageInterface>();
    private logoutSubscription: Subscription;
    private _connectionStatusChanged: Subject<MessagingConnectionStatus> = new Subject();

    /**
     * @function constructor
     * @param {AbstractMessageProvider} messageProvider
     * @param {AuthenticationService} authService
     */
    constructor(
        private messageProvider: AbstractMessageProvider,
        private authService: AuthenticationService
    ) {
        messageProvider.onMessageReceived((message: MessageInterface) => {
            this._messages.next(message);
        });

        messageProvider.onConnected(() => {
            // logger.info('Connected to messaging system');
            this._connectionStatusChanged.next({connected: true});
        });

        messageProvider.onDisconnect(() => {
            // logger.info('Disconnected from to messaging system');
            this._connectionStatusChanged.next({connected: false});
        });

        this.logoutSubscription = this.authService.logoutSubject.subscribe(
            () => {
                if (this.currentChannel) {
                    this.leaveRoom(this.currentChannel);
                }
                this.disconnect();
            },
            (err) => logger.error(err)
        );
    }

    /**
     * @function ngOnDestroy
     */
    ngOnDestroy() {
        this.logoutSubscription.unsubscribe();
    }

    /**
     * @function connectionStatusChanged
     * @description
     * @public
     * @returns {Subject<MessagingConnectionStatus>}
     */
    get connectionStatusChanged(): Subject<MessagingConnectionStatus> {
        return this._connectionStatusChanged;
    }

    /**
     * @function Messages
     * @description
     * @public
     * @returns {Subject<MessageInterface>}
     */
    get Messages(): Subject<MessageInterface> {
        return this._messages;
    }

    /**
     * @function ReplayMessages
     * @description
     * @public
     * @returns {ReplaySubject<MessageInterface>}
     */
    get ReplayMessages(): ReplaySubject<MessageInterface> {
        return this._replayMessages;
    }

    /**
     * @function connect
     * @description
     * @public
     * @param {string} username
     * @returns {void}
     */
    public connect(username: string): void {
        this.username = username;
        this.messageProvider.connect();
    }

    /**
     * @function disconnect
     * @description
     * @public
     * @returns {void}
     */
    public disconnect(): void {
        this.messageProvider.disconnect();
    }

    /**
     * @function isConnected
     * @description
     * @public
     * @returns {boolean}
     */
    public isConnected(): boolean {
        return this.messageProvider.isConnected();
    }

    /**
     * @function isConnection
     * @description
     * @public
     * @returns {boolean}
     */
    public isConnection(): boolean {
        return this.messageProvider.isConnection();
    }

    /**
     * @function resetConnection
     * @description
     * @public
     * @param {() => void} callBack
     * @returns {any}
     */
    public resetConnection(callBack?: () => void): any {
        return this.messageProvider.resetConnection(callBack);
    }

    /**
     * @function joinChannel
     * @description
     * @public
     * @param {string} channelId
     * @param {string} presenterUid
     * @returns {void}
     */
    public joinChannel(channelId: string, presenterUid: string): void {
        this.presenterUid = presenterUid;
        this.currentChannel = channelId;
        this.messageProvider.joinRoom(channelId, this.authService.credentials.username);
    }

    /**
     * @function leaveRoom
     * @description
     * @public
     * @param {string} channelId
     * @returns {void}
     */
    public leaveRoom(channelId: string): void {
        const credentials = this.authService.credentials;
        if (credentials && credentials.username) {
            this.messageProvider.leaveRoom(channelId, credentials.username);
        }
    }

    /**
     * @function messageReceived
     * @description
     * @public
     * @param {MessageInterface} message
     * @returns {void}
     */
    public messageReceived(message: MessageInterface) {
        logger.info(message);
        this._messages.next(message);
    }

    /**
     * @function sendMessage
     * @description
     * @public
     * @param {MessageInterface} message
     * @returns {void}
     */
    public sendMessage(message: MessageInterface): void {
        this.messageProvider.sendMessage(message);
    }

    /**
     * @function askForCurrentSlide
     * @description
     * @public
     * @returns {void}
     */
    public askForCurrentSlide(): void {
        this.messageProvider.askForCurrentSlide();
    }

    /**
     * @function sendTouch
     * @description
     * @public
     * @param {string} eventType
     * @param {number} currentPositionX
     * @param {number} currentPositionY
     * @returns {void}
     */
    public sendTouch(eventType: string, currentPositionX: number, currentPositionY: number): void {
        this.messageProvider.sendTouch(eventType, currentPositionX, currentPositionY, this.authService.credentials.username);
    }

    /**
     * @function sendVideoAction
     * @description
     * @public
     * @param {string} eventType
     * @param {number} index
     * @param {number} currentTime
     * @returns {void}
     */
    public sendVideoAction(eventType: string, index: number, currentTime: number): void {
        this.messageProvider.sendVideoAction(eventType, index, currentTime, this.authService.credentials.username);
    }

    /**
     * @function sendDrawingAction
     * @description
     * @public
     * @param {any} message
     * @returns {void}
     */
    public sendDrawingAction(message: any): void {
        this.messageProvider.sendDrawingAction(message);
    }

    /**
     * @function sendEraseDrawingAction
     * @description
     * @public
     * @returns {void}
     */
    public sendEraseDrawingAction(): void {
        this.messageProvider.sendEraseDrawingAction();
    }

    /**
     * @function askForHand
     * @description
     * @public
     * @returns {void}
     */
    public askForHand(): void {
        this.messageProvider.askForHand(this.authService.credentials.username);
    }

    /**
     * @function leaveHand
     * @description
     * @public
     * @returns {void}
     */
    public leaveHand(): void {
        let presenterUid: string = this.presenterUid.split('@')[0];
        if (presenterUid.indexOf('token_') !== -1) {
            presenterUid = presenterUid.substring(6);
        }
        this.messageProvider.leaveHand(presenterUid);
    }

    /**
     * @function requestAudioVideoStreaming
     * @description
     * @public
     * @returns {void}
     */
    public requestAudioVideoStreaming(): void {
        this.messageProvider.requestAudioVideoStreaming(this.presenterUid);
    }

    /**
     * @function mandatoryFileNotification
     * @description
     * @public
     * @param {string} name
     * @param {string} status
     * @returns {void}
     */
    public mandatoryFileNotification(name: string, status: string): void {
        this.messageProvider.mandatoryFileNotification(name, status);
    }

    /**
     * @function slideLoadedNotification
     * @description
     * @public
     * @param {SlideLoadedEvent} slideEvent
     * @returns {void}
     */
    public slideLoadedNotification(slideEvent: SlideLoadedEvent): void {
        this.messageProvider.slideLoadedNotification(slideEvent);
    }

    /**
     * @function presentationSlideLoadCommand
     * @description
     * @public
     * @param {CurrentSlide} slide
     * @returns {void}
     */
    public presentationSlideLoadCommand(slide: CurrentSlide): void {
        this.messageProvider.presentationSlideLoadCommand(slide);
    }

}
